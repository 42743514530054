import { FunctionComponent, useEffect, useState } from "react";
import {
  LoginForm,
  LoginFormCopy,
  ConfirmEmail,
  ConfirmEmailCopy,
  SlideOverDialog,
  LoggedInView,
  LoggedInViewCopy,
  useSlideOverDialogContext,
} from "@bluebottlecoffee/design-system";
import { useUser } from "../lib/chord/packages/web/src/hooks/actions/use-user";
import { useAnalytics } from "../lib/chord/packages/web/src/hooks/actions/use-analytics";
import { useAuth } from "../lib/chord/packages/web/src/hooks/actions/use-auth";
import { accountPage } from "../lib/link-builders";
import emailIsValid from "../lib/utils/strings/email-is-valid";

export interface DialogData {
  copy: LoginFormCopy;
  region: string;
  lang: string;
}

export const LoginDialog: FunctionComponent<DialogData> = ({
  copy,
  ...defaultProps
}) => {
  const { lang, region } = defaultProps;
  const { activeContentType } = useSlideOverDialogContext();
  const { user, loadUser } = useUser();
  const { login, logout, isLoggedIn, isFetching, getToken } = useAuth();
  const [email, setEmail] = useState<string>("");
  const confirmEmailCopy: ConfirmEmailCopy = {
    confirmModalDismiss: copy.confirmModalDismiss,
    confirmModalTitle: copy.confirmModalTitle,
    confirmModalEmailConfirmation: copy.confirmModalEmailConfirmation,
    confirmModalInstructions: copy.confirmModalInstructions,
  };
  const loggedInCopy: LoggedInViewCopy = {
    loggedInGreeting: copy.loggedInGreeting,
    viewAccountLink: copy.viewAccountLink,
    logoutButton: copy.logoutButton,
  };

  useEffect(() => {
    // @ts-ignore
    getToken().catch((_err) => {});
  }, []);

  useEffect(() => {
    if (activeContentType === "login" && isLoggedIn) {
      loadUser();
    }
  }, [isLoggedIn, activeContentType]);

  const { trackEmailCaptured } = useAnalytics();
  const onSubmit = async (
    email,
  ): Promise<{ success: boolean; message: string }> => {
    if (!emailIsValid(email)) {
      return Promise.resolve({
        success: false,
        message: copy.invalidEmailFormat,
      });
    }

    setEmail(email);
    trackEmailCaptured(email);
    try {
      await login({
        email,
        showUI: true,
        redirectURI: window.location.href,
      });
      return { success: true, message: "" };
    } catch (error) {
      return {
        success: false,
        message: copy.unableToLogInMessage,
      };
    }
  };

  const handleLogout = async () => {
    logout();
  };

  const renderDialogContent = () => {
    if (email && isFetching) {
      return <ConfirmEmail copy={confirmEmailCopy} email={email} />;
    }

    if (!isLoggedIn) {
      return (
        <LoginForm copy={copy} awaitingLinkClick={false} onSubmit={onSubmit} />
      );
    }

    return (
      <LoggedInView
        copy={loggedInCopy}
        onLogOut={handleLogout}
        user={user.data}
        accountUrl={accountPage({ slug: "personal-information", lang, region })}
      />
    );
  };

  return (
    <SlideOverDialog
      dismissText={copy.dismissText}
      title={copy.title}
      contentType="login"
    >
      {renderDialogContent()}
    </SlideOverDialog>
  );
};
