import { useDispatch } from "react-redux";
import { useMemo } from "react";
import { bindActionCreators } from "redux";
import * as actions from "../../redux/actions/analytics";

export function useAnalytics() {
  const dispatch = useDispatch();

  const trackClickCollection = useMemo(
    () => bindActionCreators(actions.trackClickCollection, dispatch),
    [dispatch],
  );

  const trackClickProduct = useMemo(
    () => bindActionCreators(actions.trackClickProduct, dispatch),
    [dispatch],
  );

  const trackCreateStockRequest = useMemo(
    () => bindActionCreators(actions.trackCreateStockRequest, dispatch),
    [dispatch],
  );

  const trackViewCart = useMemo(
    () => bindActionCreators(actions.trackViewCart, dispatch),
    [dispatch],
  );

  const trackEmailCaptured = useMemo(
    () => bindActionCreators(actions.trackEmailCaptured, dispatch),
    [dispatch],
  );

  return {
    trackClickCollection,
    trackClickProduct,
    trackCreateStockRequest,
    trackEmailCaptured,
    trackViewCart,
  };
}
