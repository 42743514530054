import { NavItem } from "@bluebottlecoffee/design-system";
import { BaseNavProps } from "../../components/LayoutWrapper";
import { NavItem as NavItemSchema } from "../sanity-schema";
import { toLinkProps } from "./link";
import { cartPage } from "../link-builders";

export function toNavItemProps(
  data: NavItemSchema,
  region: string,
  lang: string,
): NavItem {
  return {
    name: data.label[lang],
    ...((data.link?.internal || data.link?.external) && {
      link: toLinkProps(data.link, region, lang),
    }),
    ...(data.navItems && {
      navItems: data.navItems.map((i) => toNavItemProps(i, region, lang)),
    }),
  };
}

export function toNavProps(
  cartItemCount: number,
  navItems: NavItemSchema[],
  region: string,
  lang: string,
): BaseNavProps {
  const cartLinkUrl = cartPage({ region, lang });
  return {
    navItems: navItems.map((i) => toNavItemProps(i, region, lang)),
    cartItemCount,
    hasTextLight: false,
    transparentMode: false,
    cartLink: {
      text: "",
      url: cartLinkUrl,
    },
  };
}
