import { FunctionComponent, ReactNode, useEffect } from "react";
import Head from "next/head";
import {
  AnnouncementBar,
  Footer,
  LayoutWithNav,
  Nav,
  LoginFormCopy,
  NavProps,
} from "@bluebottlecoffee/design-system";
import { BaseProps } from "../lib/sanity/shared";
import { toAnnouncementBarProps } from "../lib/transformers/announcement-bar";
import { toFooterProps } from "../lib/transformers/footer";
import { toNavProps } from "../lib/transformers/nav";
import { LoginDialog } from "./LoginDialog";
import { homePage } from "../lib/link-builders";
import { useCart } from "../lib/chord/packages/web/src/hooks/actions/use-cart";
import { toMetaTagProps } from "../lib/transformers/metaTag";
import { MetaTags as MetaTagsSchema } from "../lib/sanity-schema";
import { ConsentManagerCopyProps } from "./ConsentManagerWrapper";
import { handleEmailOptIn } from "../lib/component-utils/email-opt-in";
import { ChordUseCartReturnType } from "../lib/chord/types";

type LayoutWrapperProps = BaseProps & {
  consentManagerCopy: ConsentManagerCopyProps;
  isTransparent?: boolean;
  loginDialogCopy: LoginFormCopy;
  metaTags?: MetaTagsSchema;
  pageTitle?: string;
  slideOutContent?: ReactNode[];
};

export type BaseNavProps = Omit<NavProps, "brandLink">;

export const LayoutWrapper: FunctionComponent<LayoutWrapperProps> = ({
  announcementBar,
  children,
  footer,
  isTransparent,
  lang,
  loginDialogCopy,
  metaTags,
  nav,
  pageTitle,
  region,
  slideOutContent,
}) => {
  const { cart, loadCart }: ChordUseCartReturnType = useCart();

  useEffect(() => {
    loadCart();
  }, [loadCart]);

  const cartItemCount =
    cart?.data?.lineItems?.reduce((result, li) => result + li.quantity, 0) ?? 0;

  // Create a set of footerProps
  const footerProps = toFooterProps(footer, region, lang, (email) =>
    handleEmailOptIn(email),
  );

  // Push a "Do Not Sell My Personal Information" link onto the finePrint array
  footerProps.finePrint.push({
    url: "#",
    isFunctionLink: true,
    onClick: async () => {
      const { openConsentManager } = await import("@segment/consent-manager");
      openConsentManager();
    },
    text: "Do Not Sell My Personal Information",
  });

  return (
    <>
      <Head>
        <title>{pageTitle}</title>
        {metaTags?.map((tag) => (
          <meta {...toMetaTagProps(tag, lang)} />
        ))}
      </Head>

      <LayoutWithNav
        transparentNav={isTransparent}
        announcements={
          announcementBar?.announcements?.length > 0 && (
            <AnnouncementBar
              {...toAnnouncementBarProps(announcementBar, lang)}
            />
          )
        }
        nav={
          nav?.navItems?.length > 0 && (
            <Nav
              {...toNavProps(cartItemCount, nav.navItems, region, lang)}
              transparentMode={isTransparent}
              hasTextLight={isTransparent}
              brandLink={{ text: "", url: homePage({ region, lang }) }}
            />
          )
        }
        bgColor="cream"
      >
        <>
          {[
            ...slideOutContent,
            <LoginDialog copy={loginDialogCopy} region={region} lang={lang} />,
          ]}
          <main id="main">{children}</main>
          {footer && <Footer {...footerProps} />}
        </>
      </LayoutWithNav>
    </>
  );
};

LayoutWrapper.defaultProps = {
  isTransparent: false,
  metaTags: [],
  pageTitle: "Blue Bottle Coffee",
  slideOutContent: [],
};
