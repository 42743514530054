import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import * as actions from '~/redux/actions/cart'
import { useMemo } from 'react'
import { bindActionCreators } from 'redux'

export function useCart() {
  const cart = useSelector(state => state.cart, shallowEqual)
  const dispatch = useDispatch()

  const addPromoCode = useMemo(
    () => bindActionCreators(actions.addPromoCode, dispatch),
    [dispatch]
  )

  const addReferralIdentifier = useMemo(
    () => bindActionCreators(actions.addReferralIdentifier, dispatch),
    [dispatch]
  )

  const addToCart = useMemo(
    () => bindActionCreators(actions.addToCart, dispatch),
    [dispatch]
  )

  const checkout = useMemo(
    () => bindActionCreators(actions.checkout, dispatch),
    [dispatch]
  )

  const finalizeCheckout = useMemo(
    () => bindActionCreators(actions.finalizeCheckout, dispatch),
    [dispatch]
  )

  const loadCart = useMemo(
    () => bindActionCreators(actions.loadCart, dispatch),
    [dispatch]
  )

  const modifyCart = useMemo(
    () => bindActionCreators(actions.modifyCart, dispatch),
    [dispatch]
  )

  const modifyQuantity = useMemo(
    () => bindActionCreators(actions.modifyQuantity, dispatch),
    [dispatch]
  )

  const modifyGiftCards = useMemo(
    () => bindActionCreators(actions.modifyGiftCards, dispatch),
    [dispatch]
  )

  const removeFromCart = useMemo(
    () => bindActionCreators(actions.removeFromCart, dispatch),
    [dispatch]
  )

  const removePromoCode = useMemo(
    () => bindActionCreators(actions.removePromoCode, dispatch),
    [dispatch]
  )

  const subscribeProduct = useMemo(
    () => bindActionCreators(actions.subscribeProduct, dispatch),
    [dispatch]
  )

  return {
    cart,
    addPromoCode,
    addReferralIdentifier,
    addToCart,
    checkout,
    finalizeCheckout,
    loadCart,
    modifyCart,
    modifyQuantity,
    modifyGiftCards,
    removeFromCart,
    removePromoCode,
    subscribeProduct
  }
}
