import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { useMemo, useEffect } from "react";
import { bindActionCreators } from "redux";
import * as actions from "../../redux/actions/user";

export function useUser() {
  const user = useSelector((state) => state.user, shallowEqual);
  const dispatch = useDispatch();

  const loadUser = useMemo(
    () => bindActionCreators(actions.loadUser, dispatch),
    [ dispatch ],
  );

  useEffect(() => {
    if (!user) {
      loadUser();
    }
  }, [ loadUser, user ]);

  const loadUserReferralIdentifier = useMemo(
    () => bindActionCreators(actions.loadUserReferralIdentifier, dispatch),
    [ dispatch ],
  );

  const modifyUser = useMemo(
    () => bindActionCreators(actions.modifyUser, dispatch),
    [ dispatch ],
  );

  const loadUserSubscriptions = useMemo(
    () => bindActionCreators(actions.loadUserSubscriptions, dispatch),
    [ dispatch ],
  );

  return {
    user,
    loadUser,
    loadUserReferralIdentifier,
    modifyUser,
    loadUserSubscriptions,
  };
}
